// TODO: remove this file
import React, { useRef, Fragment, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Card, AGTable, Button } from "components";
import AsnSaveErrors from "./AsnSaveErrors";
import { createAsnDetailGrid } from "../tables/createAsnDetailGrid";
import moment from "moment";
import {
  deleteAsn,
  saveAsn,
  createAsn,
  unflagAsn,
  saveAsnErrors,
  cancelEditAsn,
} from "redux/actions/asn.action";
import { unlockPoOnCancel } from "redux/actions/pos.action";
import { isAsnSaveable } from "../utils";
import config from "config/config";
import { UncontrolledAlert } from "reactstrap";

const EditAsn = ({
  currentPo,
  asn,
  saveAsn,
  createAsn,
  unflagAsn,
  saveAsnErrors,
  cancelEditAsn,
  warehouseId,
  unlockPoOnCancel,
}) => {
  const [quantityError, setQuantityError] = useState(false);
  const [totalCarton, setTotalCarton] = useState(0);
  const [errorName, setErrorName] = useState();
  let weightInput = useRef(null);

  const handleCancelEdit = () => {
    cancelEditAsn(currentPo);
    unlockPoOnCancel(currentPo.id);
  };

  const handleSave = () => {
    const weight = weightInput.current.value;
    const [id, lines, errors] = isAsnSaveable(
      currentPo,
      asn.lines,
      weight,
      totalCarton,
    );

    if (errors.length) {
      weightInput.current.focus();
      return saveAsnErrors(errors);
    }

    const { isEditingAsn, currentAsn } = asn;

    const data = {
      po_id: id,
      warehouse_id: warehouseId,
      weight,
      details: lines,
      total_cartons: +totalCarton,
    };

    if (isEditingAsn) {
      data.asn_id = currentAsn?.id;
      saveAsn(data);
      if (config.getFlaggedFeatureFromLocalStorage()) {
        unflagAsn({ asns: [asn.currentAsn.id] });
      }
    } else {
      createAsn(data);
    }
  };

  useEffect(() => {
    if (asn.lines?.length === 1 && totalCarton === 0) setTotalCarton(1);
    if (asn.lines?.length === 0) setTotalCarton(0);
  }, [asn.lines]);

  return (
    <Card title="ASN">
      {asn?.lines.length > 0 ? (
        <div className="create-asn-table">
          {asn.cancelDate && (
            <Fragment>
              <h3>Cancel Date:</h3>
              <h5>{moment(asn.cancelDate).format("YYYY-MM-DD")}</h5>
            </Fragment>
          )}
          {asn.barcodes}
          <div style={{ width: "1158px" }}>
            <AGTable
              tableName="editAsn"
              isDoneLoading={true}
              gridConfig={createAsnDetailGrid}
              rowData={asn.lines}
            />
            <br />
            <div>
              {quantityError && (
                <>
                  <UncontrolledAlert color="danger" style={{ width: "600px" }}>
                    <span>{errorName}</span>
                  </UncontrolledAlert>
                </>
              )}
              {asn.asnSaveErrors.length > 0 && (
                <AsnSaveErrors errors={asn.asnSaveErrors} />
              )}
              <div style={{ display: "flex", justifyContent: "flex-start" }}>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "0.5rem",
                  }}
                >
                  <label style={{ margin: "0.1rem" }}>Weight:</label>
                  <input
                    ref={weightInput}
                    type="number"
                    placeholder="0 lbs."
                    min="0"
                    step="1"
                    style={{ textAlign: "right", width: "4rem" }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "0.5rem",
                  }}
                >
                  <label style={{ margin: "0.1rem" }}>Total Cartons:</label>
                  <input
                    type="number"
                    placeholder="0"
                    min="0"
                    step="1"
                    value={totalCarton}
                    onChange={(e) => setTotalCarton(e.target.value)}
                    style={{ textAlign: "right", width: "4rem" }}
                  />
                </div>
                <div className="btn-group float-right">
                  {!asn.savingAsn && asn.lines.length > 0 ? (
                    <>
                      <Button
                        color="primary"
                        btnSize={"70px"}
                        onClick={handleSave}
                        style={{ margin: "4px" }}
                      >
                        Save ASN
                      </Button>
                    </>
                  ) : asn.savingAsn ? (
                    <>
                      <Button
                        color="primary"
                        style={{ display: "none" }}
                        btnSize={"70px"}
                        disabled={asn.savingAsn}
                      >
                        Save ASN
                      </Button>
                    </>
                  ) : null}
                  <Button
                    color="secondary"
                    style={{ margin: "4px" }}
                    onClick={handleCancelEdit}
                    disabled={asn.savingAsn}
                    btnSize={"40px"}
                  >
                    Cancel
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        "No data found"
      )}
    </Card>
  );
};

export default connect(({ asn, po }) => ({ asn, po }), {
  deleteAsn,
  createAsn,
  unflagAsn,
  saveAsnErrors,
  cancelEditAsn,
  saveAsn,
  unlockPoOnCancel,
})(EditAsn);
