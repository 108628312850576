import React, { useEffect } from "react";
import { connect } from "react-redux";

import VendorShipment from "./VendorShipment";
import { cancelShipment } from "redux/actions/shipment.action";
import VendorAsn from "./VendorAsn";

const VendorAsnShipment = ({ isShipmentStarted, cancelShipment }) => {
  useEffect(() => {
    return () => {
      cancelShipment();
    };
  }, []);
  return isShipmentStarted ? <VendorShipment /> : <VendorAsn />;
};

export default connect(
  ({ shipment }) => ({
    isShipmentStarted: shipment.isShipmentStarted,
  }),
  { cancelShipment },
)(VendorAsnShipment);
