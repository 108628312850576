import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { UncontrolledAlert } from "reactstrap";
import styles from "./ASNComponents.module.css";
import { Card, AGTable, Button } from "components";

import { asnAddToShipmentGrid } from "../tables/asnAddToShipmentGrid";
import { carrierGrid } from "../tables/carrierGrid";

import {
  cancelShipment,
  saveShipment,
  setLoadType,
  setQuantity,
} from "redux/actions/shipment.action";
import {
  unselectCarrier,
  getCarriers,
  setDefaultCarrier,
} from "redux/actions/carrier.action";
import { getAllWarehouses } from "redux/actions/warehouse.action";
import { asnInShipmentGrid } from "../tables/asnInShipmentGrid";
import ContactCarrier from "./ContactCarrier";
import SelectedCarrierCard from "./SelectedCarrierCard";
import { getShippableASNs } from "redux/actions/asn.action";
import { useDispatch } from "react-redux";
import { setTotalCarton } from "redux/actions/shipment.action/";
import { isIntegerInput, removeCRLF } from "screens/Pos/utils";

const VendorShipment = ({
  asn,
  shipment,
  carrier,
  warehouse,
  cancelShipment,
  saveShipment,
  setLoadType,
  setQuantity,
  unselectCarrier,
  getCarriers,
  getAllWarehouses,
  getShippableASNs,
}) => {
  const [totalCarton] = useState(9);
  const [totalweights] = useState(150);
  // const [cubicFeet] = useState(110);
  const [childValue, setChildValue] = useState("");
  const [defaultLoadType, setDefaultLoadType] = useState();
  const [palletLength, setpalletLength] = useState(40);
  const [palletWidth, setpalletWidth] = useState(48);
  const [palletHeight, setpalletHeight] = useState(48);
  const [error, setError] = useState(false);
  const [errorName, setErrorName] = useState([]);
  const [loadTypeMenually, setLoadTypeMenually] = useState(false);
  const [palletQuantity, setPalletQuantity] = useState(1);
  const dispatch = useDispatch();
  let errors = [];
  useEffect(() => {
    if (defaultLoadType == "C") setQuantity(0);
  }, [defaultLoadType]);

  useEffect(() => {
    if (!carrier.carriersUpToDate) {
      getCarriers();
    }
    if (!warehouse.allWarehousesUpToDate) {
      getAllWarehouses();
    }
  }, [
    carrier.carriersUpToDate,
    warehouse.allWarehousesUpToDate,
    getAllWarehouses,
    getCarriers,
  ]);

  const { asnsToShip, shipToStore, loadType } = shipment;
  const shipFromWarehouse = warehouse.allWarehouses.find(
    (w) => w.id === shipment.shipFromWarehouse.id,
  );

  const { selectedCarrierId } = carrier;
  let shipmentSaveable = true;
  const totalCubicFt =
    asnsToShip.length > 0
      ? asnsToShip.map(({ volume }) => volume).reduce((a, b) => a + b)
      : 0;

  const totalWeight =
    asnsToShip.length > 0
      ? asnsToShip.map(({ weight }) => weight).reduce((a, b) => a + b)
      : 0;
  const validCarriers = carrier
    ? carrier.carriers //.filter(carrierFilter(totalCubicFt, totalWeight))
    : [];
  let selectedCarrierIsValid = true;

  if (selectedCarrierId !== 0) {
    if (!validCarriers.find((c) => c.id === selectedCarrierId)) {
      shipmentSaveable = false;
      selectedCarrierIsValid = false;
    }
  } else {
    shipmentSaveable = false;
  }

  useEffect(() => {
    if (!selectedCarrierIsValid) {
      unselectCarrier();
    }
  });
  const totalCartons = asnsToShip
    ?.map((item) => item.total_cartons)
    .reduce((a, b) => a + b, 0);
  const cartonsSum = asnsToShip
    ?.map((e) => e.details?.[0].total_cartons)
    .reduce((val, sum) => val + sum, 0);

  const totalweight = asnsToShip
    .map((e) => e.weight)
    .reduce((a, c) => a + c, 0);

  useEffect(() => {
    setDefaultLoadType(childValue.name == "CHR" ? "P" : "C");
  }, [childValue]);

  // setLoadTypeMenually
  const handleChangeLoadtype = (e) => {
    setDefaultLoadType(e);
    setLoadTypeMenually(true);
  };

  useEffect(() => {
    if (loadTypeMenually == true && defaultLoadType == "P") {
      unselectCarrier();
      setLoadTypeMenually(false);
      const selectedCarrier = validCarriers.find((e) => e.name === "CHR");
      dispatch(setDefaultCarrier(selectedCarrier));
    }
  }, [loadTypeMenually]);

  useEffect(() => {
    if (loadTypeMenually == true && defaultLoadType == "C") {
      unselectCarrier();
      setLoadTypeMenually(false);
      const selectedCarrier = validCarriers.find((e) => e.name === "Fedex");
      dispatch(setDefaultCarrier(selectedCarrier));
    }
  }, [defaultLoadType]);

  useEffect(() => {
    if (defaultLoadType || asnsToShip?.length) {
      unselectCarrier();
    }

    const carrierAutoSelect =
      totalCarton <= totalCartons ||
      totalweights <= totalweight ||
      defaultLoadType == "P";
    const carrierName = carrierAutoSelect ? "CHR" : "Fedex";
    const selectedCarrier = validCarriers.find((e) => e.name === carrierName);
    if (selectedCarrier?.name == "CHR") setDefaultLoadType("P");
    if (!asnsToShip.length) setDefaultLoadType(" ");
    dispatch(setDefaultCarrier(selectedCarrier));
    dispatch(setTotalCarton({ cartonsSum, validCarriers }));
  }, [cartonsSum, totalCartons]);

  if (asnsToShip.length === 0) {
    shipmentSaveable = false;
  }

  if (loadType === "") {
    shipmentSaveable = false;
  }

  const getShipmentData = () => {
    let quantity = 0;
    if (defaultLoadType == "P") quantity = +palletQuantity;
    setLoadType(defaultLoadType);
    let comment = removeCRLF(
      document && document.getElementById("comment").value,
    );
    if (defaultLoadType == "P") {
      return {
        comment,
        carrier: selectedCarrierId,
        asns: asnsToShip.map((a) => a.id),
        loadType: defaultLoadType,
        ship_to_store: shipToStore,
        pallet_count: quantity,
        pallet_length: Number(palletLength),
        pallet_width: Number(palletWidth),
        pallet_height: Number(palletHeight),
      };
    }
    if (defaultLoadType == "C") {
      return {
        comment,
        carrier: selectedCarrierId,
        asns: asnsToShip.map((a) => a.id),
        loadType: defaultLoadType,
        ship_to_store: shipToStore,
        pallet_count: 1,
      };
    }
  };

  const handleSave = () => {
    let errors = [];

    if (defaultLoadType === "P") {
      const fields = [
        {
          name: "Pallet Quantity",
          value: palletQuantity,
          message: "Missing number of pallets",
        },
        {
          name: "Pallet Length",
          value: palletLength,
          message: "Pallet Length is required",
        },
        {
          name: "Pallet Width",
          value: palletWidth,
          message: "Pallet Width is required",
        },
        {
          name: "Pallet Height",
          value: palletHeight,
          message: "Pallet Height is required",
        },
      ];

      fields.forEach((field) => {
        if (field.value === undefined || field.value < 1) {
          setError(true);
          errors.push({ error: `${field.message}, ` });
        } else if (!isIntegerInput(field.value)) {
          setError(true);
          errors.push({
            error: `Invalid ${field.name}. Decimal values are not allowed.`,
          });
        }
      });
    }

    if (errors.length === 0) {
      setError(false);
      saveShipment(getShipmentData());
    } else {
      setErrorName(errors);
    }
  };

  if (shipment.savingSucceeded) {
    return <ContactCarrier />;
  }

  const setPalletAndCartonQty = (e) => {
    setPalletQuantity(e);
  };

  return (
    <>
      {shipment.errorMessage && (
        <UncontrolledAlert color="danger">
          {shipment.errorMessage}
        </UncontrolledAlert>
      )}
      <Card title="ASNs">
        <div style={{ display: "grid" }}>
          <Button
            color="danger"
            // className={"float-right"}
            btnSize="100px"
            onClick={cancelShipment}
            style={{
              width: "fit-content",
              justifySelf: "left",
              marginBottom: "10px",
              marginTop: "10px",
            }}
          >
            Cancel Shipment
          </Button>
          <AGTable
            tableName="vendorShipment1"
            paginate={true}
            gridConfig={asnAddToShipmentGrid}
            isDoneLoading={asn.shippableAsnsRetrieved}
            action={getShippableASNs}
            rowData={asn.shippableAsns}
            next={asn.snext}
            previous={asn.sprevious}
            totalRows={asn.stotalRows}
          />
        </div>
      </Card>

      <Card title="ASNs to Ship">
        <div>
          <div className={styles.toShipLabels}>
            {shipToStore !== "" && <h5>Ship to: {shipToStore}</h5>}
            {shipFromWarehouse && <h5>Ship From: {shipFromWarehouse.name}</h5>}

            {totalCubicFt !== 0 && (
              <>
                {/* <h5>
                Volume: {Number.parseFloat(totalCubicFt).toPrecision(4)}
                ft³
              </h5> */}
                <h5>Weight: {totalWeight}lbs</h5>
              </>
            )}
          </div>
          <AGTable
            tableName="vendorShipment2"
            paginate={true}
            gridConfig={asnInShipmentGrid}
            isDoneLoading={true}
            rowData={asnsToShip}
          />
        </div>
      </Card>
      <div style={{ display: "flex" }}>
        <Card title="Shipment">
          <form>
            <div className="form-group" style={{ width: "900px" }}>
              <div className="d-flex">
                <div style={{ width: "80%", paddingRight: "10px" }}>
                  <label htmlFor="loadType">Load Type:</label>
                  <b style={{ color: "red" }}> *</b>
                  <select
                    id="loadType"
                    className="form-control"
                    value={defaultLoadType}
                    onChange={(e) => handleChangeLoadtype(e.target.value)}
                  >
                    <option>Select Load Type</option>
                    <option value="C">Carton</option>
                    <option value="P">Pallets</option>
                  </select>
                </div>
                <div style={{ width: "20%" }}>
                  <label>
                    Number Of{" "}
                    {defaultLoadType == "P"
                      ? "Pallets"
                      : defaultLoadType == "C"
                        ? "Cartons"
                        : ""}
                  </label>
                  <input
                    type="number"
                    value={
                      // Set value based on default load type and quantity
                      defaultLoadType === "P"
                        ? palletQuantity
                        : defaultLoadType === "C"
                          ? totalCartons
                          : ""
                    }
                    // Disable input if default load type is "C"
                    disabled={defaultLoadType === "C"}
                    placeholder="0"
                    // Call setPalletAndCartonQty function when input value changes
                    onChange={(e) => setPalletAndCartonQty(e.target.value)}
                    className="form-control"
                  />
                </div>
              </div>
              <br />
              <div className={defaultLoadType == "P" ? "d-flex" : "block"}>
                <div className={defaultLoadType == "P" ? "mr-4" : ""}>
                  <label htmlFor="comment">Comment:</label>
                  <textarea
                    className="form-control"
                    rows="2"
                    cols="80"
                    id="comment"
                    placeholder="Comment"
                    maxLength="200"
                  />
                </div>
                {defaultLoadType == "P" && (
                  <div className="d-flex mt-3">
                    <div>
                      <label htmlFor="Pallet Length">Pallet Length (In) </label>
                      <input
                        type="number"
                        value={palletLength}
                        placeholder="0"
                        onChange={(e) => setpalletLength(e.target.value)}
                        className="form-control coustom-w-100"
                      />
                    </div>
                    <div className="mx-4">
                      <label htmlFor="Pallet Length">Pallet Width (In)</label>
                      <input
                        type="number"
                        value={palletWidth}
                        placeholder="0"
                        onChange={(e) => setpalletWidth(e.target.value)}
                        className="form-control coustom-w-100"
                      />
                    </div>
                    <div>
                      <label htmlFor="Pallet Length">Pallet Height (In)</label>
                      <input
                        type="number"
                        value={palletHeight}
                        placeholder="0"
                        onChange={(e) => setpalletHeight(e.target.value)}
                        className="form-control coustom-w-100"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </form>
        </Card>
      </div>
      <div style={{ display: "flex" }}>
        {selectedCarrierId === 0 ? (
          totalCubicFt !== 0 && (
            <>
              <Card title="Carriers">
                <div>
                  <AGTable
                    tableName="vendorShipment3"
                    paginate={false}
                    gridConfig={carrierGrid}
                    isDoneLoading={carrier.carriersRetrieved}
                    rowData={validCarriers}
                  />
                </div>
              </Card>
              <div style={{ width: "22px" }} />
            </>
          )
        ) : (
          <>
            <div>
              {asnsToShip.length > 0 && (
                <SelectedCarrierCard
                  setChildValue={setChildValue}
                  loadQuantity={shipment.quantity}
                />
              )}
            </div>
            <div style={{ width: "22px" }} />
          </>
        )}
      </div>

      <div>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <div style={{ width: "900px" }}>
            <Button
              color="primary"
              // className={"float-right"}
              btnSize="100px"
              disabled={!asnsToShip.length > 0 || shipment.isSaving}
              toggleLoader={shipment.isSaving}
              type="submit"
              style={{
                width: "fit-content",
                justifySelf: "left",
                marginBottom: "10px",
                marginTop: "10px",
                marginLeft: "30px",
              }}
            >
              Save Shipment
            </Button>
            <div className="ml-4 mt-2">
              {errorName?.length > 0 && (
                <UncontrolledAlert color="danger">
                  <p>Failed to save Shipment:</p>
                  {errorName.map((error, idx) => (
                    <div key={idx}>{error.error}</div>
                  ))}
                </UncontrolledAlert>
              )}
            </div>
          </div>
        </form>
      </div>
    </>
  );
};

export default connect(
  ({ asn, shipment, carrier, warehouse }) => ({
    asn,
    shipment,
    carrier,
    warehouse,
  }),
  {
    cancelShipment,
    saveShipment,
    setLoadType,
    setQuantity,
    unselectCarrier,
    getCarriers,
    getAllWarehouses,
    getShippableASNs,
  },
)(VendorShipment);
