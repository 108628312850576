import { ShipmentASNs } from "components/AGTable/NewColumns/ShipmentASNs";
import { ShipmentCarrier } from "components/AGTable/NewColumns/ShipmentCarrier";
import { ShipmentWarehouse } from "components/AGTable/NewColumns/ShipmentWarehouse";
import { ShipmentUpdate } from "components/AGTable/NewColumns/ShipmentUpdate";
import { ShipmentDetail } from "components/AGTable/NewColumns/ShipmentDetail";
import {
  WeightColumnDef,
  TextCellStyle,
  IconCellStyle,
  NumberCellStyle,
} from "components/AGTable/NewColumns/ColumnDefinition";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  filter: true,
  cellStyle: { display: "flex", "justify-content": "center" },
};

export const shipmentGrid = {
  columnDefs: [
    ShipmentDetail,
    {
      headerName: "Shipment #",
      width: 109,
      field: "id",
    },
    ShipmentASNs,
    {
      headerName: "Load Type",
      width: 105,
      field: "loadType",
      valueGetter: (params) => {
        const loadTypes = {
          P: "Pallets",
          M: "Mixed",
          L: "Loose",
          C: "Carton",
        };
        return loadTypes[params.data.loadType];
      },
    },
    {
      headerName: "Cartons",
      width: 85,
      field: "cartons",
    },
    // {
    //   headerName: "Vol (ft³)",
    //   cellStyle: NumberCellStyle,
    //   width: 85,
    //   field: "volume",
    //   valueGetter: (params) => {
    //     return Number.parseFloat(params.data.volume).toFixed(2);
    //   },
    // },
    WeightColumnDef,
    {
      headerName: "Destination Warehouse",
      cellStyle: IconCellStyle,
      width: 175,
      field: "destination_name",
      colId: "ship_to_store",
    },
    ShipmentWarehouse,
    ShipmentCarrier,
    {
      headerName: "Pick up Date",
      width: 115,
      field: "pick_up_date",
      filter: "RadioSetFilter",
      valueGetter: (params) => {
        return params.data.pick_up_date
          ? params.data.pick_up_date.split("T")[0]
          : "Update";
      },
    },
    {
      headerName: "Status",
      width: 109,
      field: "status",
    },
    {
      headerName: "Comment",
      cellStyle: TextCellStyle,
      width: 230,
      field: "comment",
      valueGetter: (params) => params.data.comment,
    },
    ShipmentUpdate,
  ],
  defaultColDef,
};
