import React, { useState } from "react";
import { connect } from "react-redux";
import { forgotUsersPassword } from "redux/actions/user.action";
import { Link } from "react-router-dom";
import "../RecoverPassForm/RecoverPassForm.css";

const EnterEmailForm = ({ status, forgotUsersPassword }) => {
  const [email, setEmail] = useState("");
  const handleSendEmailSubmit = (e) => {
    e.preventDefault();
    forgotUsersPassword({ email });
  };
  return (
    <form className="login-form" onSubmit={handleSendEmailSubmit}>
      <div className="card mb-0 box-container">
        <div className="card-body">
          <div className="text-center mb-3">
            <Link to="" className="floating-item">
              <i className="icon-arrow-left7" />
            </Link>

            <h5 className="mb-0">Password recovery</h5>
            {status ? (
              <span className="d-block">{status}</span>
            ) : (
              <span className="d-block">
                We will send instructions by email
              </span>
            )}
          </div>

          {!status && (
            <div>
              <div className="form-group form-group-feedback form-group-feedback-right">
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="form-control"
                  placeholder="Your email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <div className="form-control-feedback">
                  <i className="icon-mail5 text-muted" />
                </div>
              </div>
              <button
                id="sendEmail"
                type="submit"
                className="btn bg-blue btn-block"
              >
                <i className="icon-spinner11 mr-2" />
                Reset password
              </button>
            </div>
          )}
        </div>
      </div>
    </form>
  );
};

export default connect(
  ({ user: { status } }) => ({
    status,
  }),
  { forgotUsersPassword },
)(EnterEmailForm);
