import { CartonName } from "components/AGTable/NewColumns/CartonName";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
};

export const asnDetailGrid = {
  columnDefs: [
    {
      headerName: "Sku",
      width: 235,
      field: "po_line.sku",
    },
    {
      headerName: "QTY",
      width: 235,
      field: "quantity",
      cellStyle: { display: "flex", "justify-content": "center" },
      width: 140,
    },
    // CartonName,
    // {
    //   headerName: "Total Cartons",
    //   cellStyle: { display: "flex", "justify-content": "center" },
    //   field: "total_cartons",
    //   width: 165,
    // },
  ],
  defaultColDef,
};
