import config from "config/config";
const goodResponses = [200, 201, 204];

const getHeaders = () => ({
  "Content-Type": "application/json",
  Authorization: window.sessionStorage.getItem("token"),
});

export default class Service {
  static async post(dispatch, url, body, types) {
    this.fetch(dispatch, "post", url, types, body);
  }
  static async get(dispatch, url, queryParams, types) {
    if (queryParams.init && queryParams.success && queryParams.error) {
      types = queryParams;
      queryParams = {};
    }
    this.fetch(dispatch, "get", url, types, queryParams);
  }

  static async put(dispatch, url, body, types) {
    this.fetch(dispatch, "put", url, types, body);
  }

  static async patch(dispatch, url, body, types) {
    this.fetch(dispatch, "patch", url, types, body);
  }

  static async delete(dispatch, url, types) {
    this.fetch(dispatch, "delete", url, types);
  }

  static async fetch(dispatch, method, url, types, body) {
    dispatch({ type: types.init, requestData: body });
    const data = await this["fetch" + method](url, body, dispatch);
    if (data.error && data.error.error === "EXPIRED TOKEN") {
      dispatch({ type: "LOGOUT" });
    }
    data.error
      ? dispatch({ type: types.error, error: data.error })
      : dispatch({ type: types.success, data: data.data, requestData: body });
  }

  static async fetchget(url, queryParams, dispatch) {
    let reportName = this.getReportName(url);
    url = Service.formatUrl(url, queryParams);
    try {
      let data = await fetch(url, {
        headers: getHeaders(),
      });
      if (reportName !== null && data.ok) {
        Service.downloadFile(data, reportName);
      }
      if (data.status === 401) {
        dispatch({ type: "ASSIGN_SESSION_EXPIRED_WARNING" });
      } else if (!(data.status >= 200 && data.status <= 299)) {
        if (
          url.pathname !== "/api/reports/asn-report/" ||
          data.status !== 404
        ) {
          dispatch({ type: "ASSIGN_ERROR_RESPONSE_WARNING" });
        }
      }
      return this.handleResponse(data);
    } catch (e) {
      return { error: e };
    }
  }

  static async fetchpost(url, body, dispatch) {
    url = Service.formatUrl(url);
    try {
      let data = await fetch(url, {
        method: "POST",
        headers: getHeaders(),
        body: JSON.stringify(body),
      });
      if (data.status === 401) {
        dispatch({ type: "ASSIGN_SESSION_EXPIRED_WARNING" });
      } else if (!(data.status >= 200 && data.status <= 299)) {
        dispatch({ type: "ASSIGN_ERROR_RESPONSE_WARNING" });
      }
      return this.handleResponse(data);
    } catch (e) {
      return { error: e };
    }
  }

  static async fetchdelete(url, body, dispatch) {
    const urlStr = url.split("/");
    console.log("Request Body: ", body);
    let id = urlStr.pop(),
      data;
    if (!id) id = urlStr.pop();

    url = Service.formatUrl(url);
    try {
      const d = await fetch(url, {
        method: "DELETE",
        headers: getHeaders(),
      });
      if (d?.status === 401) {
        dispatch({ type: "ASSIGN_SESSION_EXPIRED_WARNING" });
      }
      if (!(d?.status >= 200 && d?.status <= 299)) {
        dispatch({ type: "ASSIGN_ERROR_RESPONSE_WARNING" });
      }
      if (!goodResponses.includes(d.status))
        throw Error(
          `Error: Response came back with ${d?.status} status. ${d?.statusText}`,
        );
      try {
        data = await d.json();
      } catch (e) {}
      return data || { data: Number(id) };
    } catch (e) {
      return { error: e.message };
    }
  }

  static async fetchput(url, body, dispatch) {
    url = Service.formatUrl(url);
    try {
      let data = await fetch(url, {
        method: "PUT",
        headers: getHeaders(),
        body: JSON.stringify(body),
      });
      if (data.status === 401) {
        dispatch({ type: "ASSIGN_SESSION_EXPIRED_WARNING" });
      } else if (!(data.status >= 200 && data.status <= 299)) {
        dispatch({ type: "ASSIGN_ERROR_RESPONSE_WARNING" });
      }
      return this.handleResponse(data);
    } catch (e) {
      return { error: e };
    }
  }

  static async fetchpatch(url, body, dispatch) {
    url = Service.formatUrl(url);
    try {
      let data = await fetch(url, {
        method: "PATCH",
        headers: getHeaders(),
        body: JSON.stringify(body),
      });
      if (data.status === 401) {
        dispatch({ type: "ASSIGN_SESSION_EXPIRED_WARNING" });
      } else if (!(data.status >= 200 && data.status <= 299)) {
        dispatch({ type: "ASSIGN_ERROR_RESPONSE_WARNING" });
      }
      return this.handleResponse(data);
    } catch (e) {
      return { error: e };
    }
  }

  static async handleResponse(data) {
    if (goodResponses.includes(data.status)) {
      try {
        return { data: await data.json() };
      } catch (error) {
        return { data: await data };
      }
    } else {
      try {
        return { error: await data.json() };
      } catch (error) {
        return { error: await data };
      }
    }
  }

  static formatUrl(url, queryParams) {
    if (url.includes("?")) {
      console.warn(
        "Query Parameters should not be specified in URL string. Instead this should happen in the queryParams: object.",
      );
    }
    url = url.substr(-1) !== "/" ? url + "/" : url;
    url = new URL(config.baseUrl + url);

    for (let i in queryParams)
      if (queryParams[i]) url.searchParams.append(i, queryParams[i]);

    return url;
  }

  static downloadFile(data, fileName) {
    data.blob().then((blob) => {
      var url = window.URL.createObjectURL(blob);
      var a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      a.remove();
    });
  }

  static getReportName(url) {
    if (url.includes("reports") && !url.includes("asn-feed")) {
      let reportName = url.split("/").pop();
      return reportName.includes("asn")
        ? reportName + ".pdf"
        : reportName + ".csv";
    } else return null;
  }
}
