import React, { useEffect } from "react";
import { connect } from "react-redux";
import styles from "./details.module.css";
import { ContentHeader, Content, PageLoading, PrintMe } from "components";
import { getShipment } from "redux/actions/shipment.action";

const Details = ({ location, shipment, getShipment }) => {
  const rk = (num) => {
    return num + Math.random();
  };
  const { pathname, state } = location;
  const { currentShipment } = shipment;
  const { carrier, asns, warehouse, vendor } = currentShipment;

  const cartonCount = (details) => {
    return details.map((a) => a.total_cartons).reduce((a, b) => a + b);
  };

  const totalCartonCount = (asns) => {
    return asns.map((asn) => cartonCount(asn.details)).reduce((a, b) => a + b);
  };

  useEffect(() => {
    getShipment(pathname.split("/")[2]);
  }, [pathname, getShipment]);

  if (!shipment.shipmentRetrieved) {
    return (
      <>
        <div className="content-wrapper">
          <ContentHeader info={state} pagename="Shipment" menuType="Basic" />
          <Content>
            <PageLoading />
          </Content>
        </div>
      </>
    );
  }
  return (
    <>
      <div className={`content-wrapper ${styles.container}`}>
        <ContentHeader
          info={state}
          pagename={"Shipment: #" + currentShipment.id}
          menuType="Basic"
        />
        <Content>
          <PrintMe>
            <div className={`${styles.divToPrint} mt4`}>
              <div className={styles.reportHeader}>
                <b>SHIPMENT REPORT</b>
              </div>
              <div className={styles.headerInfo}>
                <div className={styles.vendorHeader}>
                  <b>Vendor:</b>
                  <br />
                  {vendor.name}
                  <br />
                  <b>Phone:</b>
                  <br />
                  {vendor.phone}
                </div>
                <div className={styles.warehouseHeader}>
                  <b>Warehouse:</b>
                  <br />
                  {warehouse.name}
                  <br />
                  <b>Address:</b>
                  <br />
                  {warehouse.address}
                  <br />
                  {`${warehouse.city}, ${warehouse.state} ${warehouse.zip}`}
                  <br />
                  <b>Phone:</b>
                  <br />
                  {warehouse.phone}
                </div>
                <div className={styles.shipToHeader}>
                  <b>Destination Warehouse:</b>
                  <br />
                  {currentShipment.destination_name}
                </div>
                <div className={styles.carrierHeader}>
                  <b>Pick up Date:</b>
                  <br />
                  {currentShipment.pick_up_date &&
                    currentShipment.pick_up_date.split("T")[0]}
                  <br />
                  <b>Carrier:</b>
                  <br />
                  {carrier.name}
                  <br />
                  <b>Phone:</b>
                  <br />
                  {carrier.phone}
                </div>
              </div>
              {asns &&
                asns.map((asn) => {
                  let totalCartons = cartonCount(asn.details);
                  const cancelDate = asn.details[0].po_line.cancel_date;

                  return (
                    <div key={rk(asn.id)}>
                      <hr className={styles.line} />

                      <div>
                        <table className={styles.table}>
                          <thead>
                            <tr>
                              <td>PO #</td>
                              <td>ASN #</td>
                              <td>Cancel Date</td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className={styles.w150}>
                              <td>{asn.po}</td>
                              <td>{asn.id}</td>
                              <td>{cancelDate}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div key={rk(asn.id)}>
                        <table className={styles.table}>
                          <thead>
                            <tr className={styles.w150}>
                              <td>#</td>
                              <td>SKU</td>
                              <td>QTY</td>
                            </tr>
                          </thead>
                          <tbody>
                            {asn.details
                              .sort(function (a, b) {
                                return a.po_line.sku > b.po_line.sku ||
                                  (a.po_line.sku === b.po_line.sku && a.partial)
                                  ? 1
                                  : -1;
                              })
                              .map((detail) => {
                                return (
                                  <tr key={detail.id}>
                                    <td key={rk(detail.id)}>{detail.id}</td>

                                    <td key={rk(detail.id)}>
                                      {detail.po_line.sku}
                                    </td>
                                    <td key={rk(detail.id)}>
                                      {detail.quantity}
                                    </td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </div>
                      <div />
                    </div>
                  );
                })}
              <hr className={styles.line} />
              <div>
                <table
                  className={styles.right_table}
                  style={{ borderCollapse: "collapse" }}
                >
                  <tbody>
                    <tr>
                      <td>Total Cartons</td>
                      <td>{currentShipment.cartons}</td>
                    </tr>
                    {/* <tr>
                    <td>Total Volume</td>
                    <td>
                      {Number.parseFloat(currentShipment.volume).toFixed(2) +
                        " ft"}
                      <sup>3</sup>
                    </td>
                  </tr> */}
                    <tr>
                      <td>Total Weight</td>
                      <td>{currentShipment.weight + " lbs"}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className={styles.footer}></div>
            </div>
          </PrintMe>
        </Content>
      </div>
    </>
  );
};

export default connect(({ shipment }) => ({ shipment }), { getShipment })(
  Details,
);
