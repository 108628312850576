import React from "react";
import "./header.css";
import { Link } from "react-router-dom";
import { logout } from "redux/actions/user.action";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateSidebarClass } from "redux/actions/layout.action";
import Button from "components/Button";

class Header extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebar: false,
    };
  }

  render() {
    const { user, layout } = this.props;

    function getLogo() {
      const everestLogoName = layout.sidebarClass
        ? "everest-logo"
        : "everest-logo-black";
      let logo;

      try {
        logo = require("assets/images/" + everestLogoName + ".png");
      } catch (e) {
        logo = require("assets/images/logo.svg");
      }

      return logo;
    }

    return (
      <div className="navbar navbar-expand-md navbar fixed-top ">
        <div className="d-md-none">
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbar-mobile"
          >
            <img
              src={require("assets/images/user.svg")}
              className="coustom-w-25"
              alt="User Circle Icon"
            />
          </button>
          <button
            onClick={() => this.props.updateSidebarClass("sidebar-mobile-main")}
            className="navbar-toggler sidebar-mobile-main-toggle"
            type="button"
          >
            <i className="icon-paragraph-justify3" />
          </button>
        </div>

        <div className="collapse navbar-collapse" id="navbar-mobile">
          <ul className="navbar-nav md-hidden">
            <li className="nav-item ">
              <Button
                className="bg-transparent p-0"
                onClick={() =>
                  this.props.updateSidebarClass(
                    (this.state.sidebar = !this.state.sidebar),
                  )
                }
              >
                <i className="icon-paragraph-justify3" />
              </Button>
            </li>
          </ul>

          <span className="ml-md-2 pt-0.5 mr-md-auto md-hidden">
            <img
              id="logo"
              src={getLogo()}
              className=" mr-2"
              height={layout.sidebarClass ? "46" : "18"}
              alt=""
            />
          </span>

          <ul className="navbar-nav mt-coustom">
            <li className="nav-item dropdown" />
            <li className="nav-item dropdown dropdown-user">
              <a
                href="# "
                className="navbar-nav-link d-flex align-items-center dropdown-toggle"
                data-toggle="dropdown"
              >
                <img
                  id="logo"
                  src={require("assets/images/backcountry.png")}
                  className=" mr-2"
                  width="108"
                  height="19"
                  alt=""
                />
                <span>{user.user.first_name}</span>
              </a>
              <div className="dropdown-menu dropdown-menu-right">
                <Link to="/profile" className="dropdown-item">
                  <i className="icon-user-plus" /> My profile
                </Link>
                <div className="dropdown-divider" />
                {/* <a href="# " className="dropdown-item">
                  <i className="icon-cog5" /> Account settings
                </a> */}
                <Link
                  to=""
                  onClick={this.props.logout}
                  className="dropdown-item"
                >
                  <i className="icon-switch2" /> Logout
                </Link>
              </div>
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
export default connect(
  ({ user, layout }) => ({ user, layout }),
  (dispatch) => bindActionCreators({ logout, updateSidebarClass }, dispatch),
)(Header);
