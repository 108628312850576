import React, { useEffect, useState, useCallback } from "react";
import { connect } from "react-redux";
import { logout, reminderTimeWarning } from "../redux/actions/user.action";

const IdleTimer = ({ logout, reminderTimeWarning }) => {
  const [isIdle, setIsIdle] = useState(false);
  const [lastActive, setLastActive] = useState(Date.now());
  const [remainingTime, setRemainingTime] = useState(25 * 60); // 25 minutes in seconds

  const handleActivity = useCallback(() => {
    setLastActive(Date.now());
    if (isIdle) {
      setIsIdle(false);
    }
    setRemainingTime(remainingTime); // Reset the countdown timer
  }, [isIdle]);

  useEffect(() => {
    const events = ["click", "keydown", "mousedown"];

    const resetTimer = () => handleActivity();
    events.forEach((event) => window.addEventListener(event, resetTimer));

    const checkIdleTime = () => {
      const idleTime = Date.now() - lastActive;
      const remaining = Math.max(
        0,
        remainingTime - Math.floor(idleTime / 1000),
      );
      setRemainingTime(remaining);
      if (remaining === 0 && !isIdle) {
        setIsIdle(true);
      }
    };

    const intervalId = setInterval(checkIdleTime, 1000); // Check every second

    return () => {
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      clearInterval(intervalId);
    };
  }, [lastActive, handleActivity, isIdle]);

  // useEffect(() => {}, [isIdle]);

  useEffect(() => {
    if (remainingTime === 0) {
      logout();
    } else if (remainingTime == 60) {
      reminderTimeWarning(remainingTime);
    }
  }, [remainingTime]);

  return (
    <div>
      {process?.env?.NODE_ENV === "development" && (
        <div className="mx-2">
          <>
            Auto checkout{" "}
            <b>
              : {Math.floor(remainingTime / 60)}:
              {("0" + (remainingTime % 60)).slice(-2)}
            </b>
          </>
        </div>
      )}
    </div>
  );
};

export default connect(null, {
  logout,
  reminderTimeWarning,
})(IdleTimer);
