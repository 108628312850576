import {
  PoCancelDateColumnDefinition,
  FlaggedColumnDefinition,
  PoStartDateColumnDefinition,
  ShipToStoreColumnDefinition,
  VendorColumnDef,
  DivisionColumnDef,
  RemainingQtyColumnDef,
} from "components/AGTable/NewColumns/ColumnDefinition";
import { ManagePo } from "components/AGTable/NewColumns/ManagePo";
import { ManagePoViaPoNumber } from "components/AGTable/NewColumns/ManagePoViaPoNumber";
import config from "config/config";

const defaultColDef = {
  sortable: true,
  resizable: true,
  cellClass: "no-border",
  cellStyle: { display: "flex", "justify-content": "center" },

  filter: true,
};

const columnDefs = [
  ManagePo,
  VendorColumnDef("vendor"),
  ManagePoViaPoNumber,
  PoStartDateColumnDefinition,
  PoCancelDateColumnDefinition,
  ShipToStoreColumnDefinition,
  {
    headerName: "# of ASNs",
    width: 115,
    field: "asn_count",
  },
  {
    headerName: "Remaining Qty",
    width: 120,
    field: "remaining_qty",
    sortable: false,
  },
  // RemainingQtyColumnDef({ field: "remaining_qty", colId: "remaining_qty"}),
  // CreatedAtColumnDefinition,
  // {
  //   headerName: "Order Date",
  //   field: "order_date",
  //   filter: "RadioSetFilter",
  //   width: 105
  // },
  DivisionColumnDef,
  // SizeColumnDef
];

if (config.getFlaggedFeatureFromLocalStorage())
  columnDefs.splice(12, 0, FlaggedColumnDefinition);

export const poClientGrid = {
  columnDefs: columnDefs,
  defaultColDef,
};
