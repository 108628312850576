import React from "react";

const cellRender = ({ data, context }) => {
  const cartonId = data?.carton;
  const cartons = context?.componentParent?.props?.carton?.allCartons;
  const foundCarton = cartons?.find((carton) => carton.id === cartonId);
  const cartonName = foundCarton?.name;
  return (
    <>
      <p>{cartonName}</p>
    </>
  );
};

export const CartonName = {
  headerName: "Carton",
  cellStyle: { display: "flex", "justify-content": "center" },
  cellRendererFramework: cellRender,
  width: 125,
};
