import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { updateShipmentStatus } from "redux/actions/shipment.action";
// import moment from "moment";
import ModalFooterUpdateClose from "../components/ModalFooterUpdateClose";

class ShipmentStatusUpdateForm extends React.Component {
  state = {
    data: {},
    orderCreate: [],
    status: "",
  };
  updateShipmentStatus(field, value) {
    let st = this.state;
    st.data[field] = value;
    this.setState(st);
  }
  closeModal() {
    this.closeButton.click();
  }
  componentDidMount() {
    const {
      modal: { selectedData },
    } = this.props;
    const spliceIndexes = {
      Created: 0,
      "Order Created": 0,
      Booked: 1,
      "In Transit": 2,
      Delivered: 5,
      Canceled: 4,
      Rejected: 5,
    };

    let orderCreate = [
      "Booked",
      "In Transit",
      "Delivered",
      "Canceled",
      "Rejected",
    ];

    const spliceIndex = spliceIndexes[selectedData?.status];

    if (spliceIndex !== undefined) {
      orderCreate.splice(0, spliceIndex);
    }

    this.setState({ orderCreate });
  }
  checkCanUpdate(selectedData) {
    return (
      selectedData?.status !== "Rejected" &&
      selectedData?.status !== "Delivered" &&
      selectedData?.carrier?.name !== "CHR"
    );
  }

  render() {
    const {
      modal: { selectedData },
      user: { user },
    } = this.props;

    // eslint-disable-next-line
    this.state.data.id = selectedData.id;
    const canUpdate = this.checkCanUpdate(selectedData);
    // if (user?.vendor) {
    //   return (
    //     <>
    //       <form
    //         onSubmit={(e) => {
    //           e.preventDefault();
    //           this.props.updateShipmentStatus(this.state.data);
    //           this.closeModal();
    //         }}
    //       >
    //         <div className="modal-body">
    //           <div className="form-row">
    //             <div className="form-group col-md-12">
    //               <label htmlFor="comment">Comment </label>
    //               <textarea
    //                 className="form-control"
    //                 id="comment"
    //                 required
    //                 placeholder="Comment"
    //                 maxLength="200"
    //                 defaultValue={selectedData.comment}
    //                 onChange={(e) => {
    //                   this.updateShipmentStatus("comment", e.target.value);
    //                 }}
    //               />
    //             </div>
    //           </div>
    //           <ModalFooterUpdateClose
    //             inputRef={(button) => (this.closeButton = button)}
    //           />
    //         </div>
    //       </form>
    //     </>
    //   );
    // } else
    {
      return (
        <>
          <form
            onSubmit={(e) => {
              e.preventDefault();
              this.props.updateShipmentStatus(this.state.data);
              this.closeModal();
            }}
          >
            <div className="modal-body">
              <div className="form-row">
                <div className="form-group col-md-12">
                  {canUpdate && (
                    <>
                      <label htmlFor="status">Status </label>
                      <select
                        className="form-control"
                        defaultValue={selectedData?.status}
                        onChange={(e) =>
                          this.updateShipmentStatus("status", e.target.value)
                        }
                      >
                        <option value="" hidden>
                          Select Status
                        </option>
                        {this.state.orderCreate.map((status, index) => (
                          <option key={index} value={status}>
                            {status}
                          </option>
                        ))}
                      </select>
                    </>
                  )}
                  <label htmlFor="comment" className="pt-2">
                    Comment{" "}
                  </label>
                  <textarea
                    className="form-control"
                    id="comment"
                    placeholder="Comment"
                    maxLength="200"
                    defaultValue={selectedData.comment}
                    onChange={(e) => {
                      this.updateShipmentStatus("comment", e.target.value);
                    }}
                  />
                </div>
              </div>
              <ModalFooterUpdateClose
                inputRef={(button) => (this.closeButton = button)}
              />
            </div>
          </form>
        </>
      );
    }
  }
}
export default connect(
  ({ modal, user, shipment }) => ({ modal, user, shipment }),
  (dispatch) => bindActionCreators({ updateShipmentStatus }, dispatch),
)(ShipmentStatusUpdateForm);
